<template>
  <div class="brand-touchpoint">
    <UiContainer large with-actions>
      <section class="brand-touchpoint__section">
        <UiTitle :title="$t('brands.id.touchpoint.title')" />
        <UiSubtitle :subtitle="$t('brands.id.touchpoint.subtitle')" />
        <div class="tw-flex tw-flex-col tw-mt-6">
          <v-card
            v-for="(touchpoint, index) in brandModel"
            :key="touchpoint.id || 'new touchpoint'"
            class="brand-touchpoint__section__card"
          >
            <div
              class="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-gap-4"
              @click="editTouchpointConfig(touchpoint)"
            >
              <v-icon>{{ icons.mdiQrcodeEdit }}</v-icon>
              <UiLabel class="tw-cursor-pointer" id="name" :label="touchpoint.name" />
              <UiSubtitle :subtitle="touchpoint.url" />
              <v-btn
                v-if="confirmDeleteIndex !== index + 1"
                @click.stop="
                  addTouchpoint && isNewTouchpoint(touchpoint)
                    ? abortCreateTouchpoint()
                    : (confirmDeleteIndex = index + 1)
                "
                class="tw-ml-auto"
                icon
              >
                <v-icon color="#ff6464">
                  {{ addTouchpoint && isNewTouchpoint(touchpoint) ? icons.mdiCloseCircle : icons.mdiDelete }}
                </v-icon>
              </v-btn>
              <div class="tw-ml-auto tw-flex tw-items-center" v-else>
                <UiSubtitle class="tw-mr-4" :subtitle="$t('brands.id.touchpoint.delete')" />
                <v-btn icon>
                  <v-icon color="success" @click.stop="deleteTouchpoint(touchpoint)">
                    {{ icons.mdiCheckboxMarkedCircle }}
                  </v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon @click.stop="confirmDeleteIndex = 0">{{ icons.mdiCloseCircle }}</v-icon>
                </v-btn>
              </div>
            </div>

            <v-expand-transition>
              <div
                v-if="(editing && editThisTouchpoint(touchpoint.id)) || (addTouchpoint && isNewTouchpoint(touchpoint))"
              >
                <v-divider></v-divider>
                <form
                  class="brand-touchpoint__section__card__form"
                  @submit.prevent="onSubmit"
                  novalidate
                  autocomplete="off"
                >
                  <section class="brand-touchpoint__section__card__form__section__grid">
                    <ui-block
                      id="touchpointNameLabel"
                      class="required-asterisk"
                      :label="$t('brands.id.touchpoint.name.label')"
                    >
                      <template v-slot:body>
                        <v-text-field
                          v-model="currentTouchpointEdit.name"
                          id="touchpointName"
                          solo
                          flat
                          outlined
                          :placeholder="$t('brands.id.touchpoint.name.placeholder')"
                          :error-messages="nameErrors"
                        />
                      </template>
                    </ui-block>
                    <ui-block
                      id="touchpointSlugLabel"
                      class="required-asterisk"
                      :label="$t('brands.id.touchpoint.slug.label')"
                    >
                      <template v-slot:body>
                        <v-text-field
                          v-model="currentTouchpointEdit.slug"
                          id="touchpointSlug"
                          solo
                          flat
                          outlined
                          disabled
                        />
                      </template>
                    </ui-block>
                  </section>
                  <section class="brand-touchpoint__section__card__form__section">
                    <ui-block
                      id="touchpointUrlLabel"
                      class="required-asterisk"
                      :label="$t('brands.id.touchpoint.url.label')"
                    >
                      <template v-slot:body>
                        <v-text-field
                          v-model="currentTouchpointEdit.url"
                          id="touchpointUrl"
                          solo
                          flat
                          outlined
                          :placeholder="$t('brands.id.touchpoint.url.placeholder')"
                          :error-messages="urlErrors"
                        />
                        <UiSubtitle class="tw-font-semibold" :subtitle="$t('brands.id.touchpoint.url.warning.label')" />
                        <div
                          class="tw-text-sm tw-mt-1 tw-ml-2 tw-text-gray-500 dark:tw-text-gray-400"
                          v-html="$t('brands.id.touchpoint.url.warning.rules')"
                        ></div>
                      </template>
                    </ui-block>
                  </section>
                  <section class="brand-touchpoint__section__card__form__section">
                    <ui-block
                      id="touchpointDomainIdLabel"
                      class="required-asterisk"
                      :label="$t('brands.id.touchpoint.domainId.label')"
                    >
                      <template v-slot:body>
                        <v-text-field
                          v-model="currentTouchpointEdit.landing_domain_id"
                          id="touchpointUrl"
                          solo
                          flat
                          outlined
                          :placeholder="$t('brands.id.touchpoint.domainId.placeholder')"
                        />
                      </template>
                    </ui-block>
                  </section>
                  <section class="brand-touchpoint__section__card__form__section">
                    <ui-block id="touchpointJsonLabel" :label="$t('brands.id.touchpoint.json.label')">
                      <template v-slot:body>
                        <v-textarea
                          :rules="[value => (checkJsonValid(value) ? true : $t('brands.id.touchpoint.json.error'))]"
                          v-model="jsonSetting"
                          id="touchpointJson"
                          solo
                          flat
                          outlined
                          auto-grow
                          no-resize
                          :placeholder="$t('brands.id.touchpoint.json.placeholder')"
                          :error-messages="jsonErrors"
                        />
                      </template>
                    </ui-block>
                  </section>
                  <v-btn
                    class="tw-mb-2 tw-flex tw-mx-auto"
                    type="submit"
                    rounded
                    color="primary"
                    :loading="saving"
                    :disabled="saving"
                  >
                    {{ $t('button.save') }}
                  </v-btn>
                </form>
              </div>
            </v-expand-transition>
          </v-card>

          <v-btn
            key="button"
            v-if="!editing && !addTouchpoint"
            class="tw-self-center tw-my-6"
            color="primary"
            rounded
            outlined
            @click="addTouchpointConfig"
          >
            <v-icon dense left> {{ icons.mdiPlus }} </v-icon>
            {{ $t('brands.id.touchpoint.add') }}
          </v-btn>
        </div>
      </section>
      <UiActions large centered>
        <v-btn v-if="displaySaveButton" type="submit" rounded color="primary" :loading="saving" :disabled="saving">
          {{ $t('button.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>
  </div>
</template>

<script>
import { mdiPlus, mdiQrcodeEdit, mdiDelete, mdiCheckboxMarkedCircle, mdiCloseCircle } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
import { clone, stringToSlug } from '@/utils/utilities.util'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiBlock from '@/components/UI/Block.vue'
import { mapState } from 'vuex'

export default {
  name: 'BrandTouchpoint',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiLabel,
    UiBlock,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentBrand: {
      type: Object,
      required: true,
    },
    touchpoints: {
      type: Array,
      required: true,
    },
  },
  watch: {
    'currentTouchpointEdit.name'() {
      if (this.currentTouchpointEdit.name) {
        this.currentTouchpointEdit = {
          ...this.currentTouchpointEdit,
          slug: stringToSlug(this.currentTouchpointEdit.name || ''),
        }
      }
    },
    touchpoints: {
      deep: true,
      handler() {
        this.brandModel = clone(this.touchpoints)
      },
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiQrcodeEdit,
      mdiDelete,
      mdiCheckboxMarkedCircle,
      mdiCloseCircle,
    },
    addTouchpoint: false,
    editing: false,
    confirmDeleteIndex: 0,
    currentTouchpointEdit: {},
    brandModel: {},
    displaySaveButton: false,
    validationIsAvailable: true,
  }),
  mounted() {
    this.brandModel = clone(this.touchpoints)
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
    }),
    jsonSetting: {
      get() {
        return JSON.stringify(this.currentTouchpointEdit.json_settings, null, 4)
      },
      set(jsonSetting) {
        const jsonParsed = this.checkJsonValid(jsonSetting)
        if (jsonParsed) {
          this.currentTouchpointEdit.json_settings = jsonParsed
          this.validationIsAvailable = true
        } else {
          if (JSON.stringify(this.currentTouchpointEdit.json_settings) === '{}') {
            this.currentTouchpointEdit.json_settings = {}
          }
          this.validationIsAvailable = false
        }
      },
    },
    nameErrors() {
      const errors = []
      if (!this.$v.currentTouchpointEdit.name.$dirty) return errors
      !this.$v.currentTouchpointEdit.name.required && errors.push(this.$t('error.required'))
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.currentTouchpointEdit.url.$dirty) return errors
      !this.$v.currentTouchpointEdit.url.required && errors.push(this.$t('error.required'))
      !this.$v.currentTouchpointEdit.url.url && errors.push(this.$t('error.url'))
      !this.$v.currentTouchpointEdit.url.requiredHttps && errors.push(this.$t('error.urlRequiredHttps'))
      return errors
    },
    jsonErrors() {
      const errors = []
      if (!this.$v.jsonSetting.$dirty) return errors
      !this.$v.jsonSetting.isJsonValid && errors.push(this.$t('error.json'))
      return errors
    },
  },
  methods: {
    checkJsonValid(jsonSetting) {
      try {
        return JSON.parse(jsonSetting.replace(/\s/g, '').replace(/'/g, '"'))
      } catch (error) {
        return false
      }
    },
    isNewTouchpoint(thouchpoint) {
      return typeof thouchpoint.id === 'undefined'
    },
    editThisTouchpoint(id) {
      return this.currentTouchpointEdit.id === id
    },
    deleteTouchpoint(touchpoint) {
      this.brandModel.splice(this.brandModel.indexOf(touchpoint), 1)
      this.$emit('updateTouchpoints', { brand: this.brandModel, brandId: this.currentBrand.id })
      this.closeTouchpointConfig()
      this.addTouchpoint = false
    },
    addTouchpointConfig() {
      if (!this.currentClient) {
        throw new Error('currentClient is not defined in store, go back to the clients page and navigate here')
      }
      const newTouchpoint = {
        name: '',
        slug: '',
        url: '',
        clientId: this.currentClient.uuid,
        json_settings: {},
        status: 1,
      }
      this.addTouchpoint = true
      this.brandModel.push(newTouchpoint)
      this.currentTouchpointEdit = clone(newTouchpoint)
    },
    editTouchpointConfig(touchpoint) {
      if (this.editThisTouchpoint(touchpoint.id)) {
        if (!this.addTouchpoint) {
          this.editing = false
          this.closeTouchpointConfig()
        }
      } else {
        if (this.addTouchpoint) {
          this.abortCreateTouchpoint()
        }
        this.editing = true
        this.currentTouchpointEdit = touchpoint
      }
    },
    abortCreateTouchpoint() {
      this.brandModel.pop()
      this.reinitialiseEvent()
    },
    closeTouchpointConfig() {
      this.confirmDeleteIndex = 0
      this.editing = false
      this.currentTouchpointEdit = {}
    },
    reinitialiseEvent() {
      this.addTouchpoint = false
      this.validationIsAvailable = true
      this.closeTouchpointConfig()
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (!this.currentTouchpointEdit.client_id) {
          if (!this.currentClient) {
            throw new Error('currentClient is not defined in store, go back to the clients page and navigate here')
          }
          this.currentTouchpointEdit.client_id = this.currentClient.uuid
        }

        const touchpointHaveId = this.currentTouchpointEdit.id
        this.brandModel.splice(
          this.brandModel.findIndex(touchpoint => touchpoint.id === touchpointHaveId),
          1,
          this.currentTouchpointEdit
        )
        this.$emit('updateTouchpoints', { brand: this.brandModel, brandId: this.currentBrand.id })
        this.$v.$reset()
        this.reinitialiseEvent()
      }
    },
  },

  validations() {
    return {
      currentTouchpointEdit: {
        name: {
          required,
        },
        slug: {
          required,
        },
        url: {
          required,
          url,
          requiredHttps: url => url.startsWith('https://'),
        },
      },
      jsonSetting: {
        isJsonValid: () => this.validationIsAvailable,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.brand-touchpoint {
  @apply tw-h-full;

  &__section {
    @apply tw-mt-4;

    &__card {
      @apply tw-my-2 tw-p-4 tw-shadow;

      &__form {
        &__section {
          @apply tw-mt-6;
          &:first-child {
            @apply tw-mt-0;
          }

          &__grid {
            @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6;
          }
        }
      }
    }
  }
}
</style>
